import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { useDispatch } from "react-redux";

import Home from "./components/layouts/Home";
import isEmpty from "./utils/isEmpty";
import { setUserDetails } from "./actions/userActions";
import NotificationBar from "./components/NotificationBar/NotificationBar";
import { commonServices } from "./services/commonServices";

function App() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    checkDeploymentStatus();
    const interval = setInterval(async () => {
      checkDeploymentStatus();
    }, 5 * 60 * 1000);

    return () => {
      clearInterval(interval);
    };
  }, [navigate]);

  useEffect(() => {
    authChecker();
  }, []);

  // check deployment status and show maintenance page
  const checkDeploymentStatus = async () => {
    const deployedTime = localStorage.getItem("deployedTime");
    const response = await commonServices.getDeploymentStatus();

    if (!isEmpty(response.data)) {
      if (response.data.isMaintenance === true) {
        if (window.location.pathname !== "/maintenance") {
          window.location.href = `/maintenance`;
        }
      } else if (
        response.data.isMaintenance === false &&
        window.location.pathname === "/maintenance"
      ) {
        window.location.href = `/`;
      }
      if (isEmpty(deployedTime)) {
        localStorage.setItem("deployedTime", response.data.deployedTime);
        clearCacheData();
      } else {
        if (deployedTime < response.data.deployedTime) {
          clearCacheData();
          localStorage.setItem("deployedTime", response.data.deployedTime);
        }
      }
    }
  };

  // clear cache data and reload the page
  const clearCacheData = () => {
    if ("caches" in window) {
      caches.keys().then((names) => {
        names.forEach((name) => {
          caches.delete(name);
        });
      });
    }
    window.location.reload(true);
  };

  const authChecker = () => {
    let { trackTraceJWToken, rolePermissions } = localStorage;
    // Check for token
    if (trackTraceJWToken) {
      // Decode token and get user info and exp
      try {
        let decoded = jwt_decode(trackTraceJWToken);
        dispatch(setUserDetails(decoded));
        // Check token expiry
        const currentTime = Date.now() / 1000;
        if (decoded.exp < currentTime || isEmpty(rolePermissions)) {
          localStorage.removeItem("trackTraceJWToken");
          localStorage.removeItem("rolePermissions");
          localStorage.removeItem("userInfo");
          navigate("/");
        } else {
          console.log(
            `Auto-logout in ${Math.round(decoded.exp - currentTime)} seconds`
          );
        }
      } catch (error) {
        navigate("/");
      }
    }
  };

  const userFrom = localStorage.getItem("isLoggedInFrom");

  return (
    <div className="App">
      <Home />
      {!isEmpty(userFrom) && userFrom === "Admin" && <NotificationBar />}
    </div>
  );
}

export default App;
