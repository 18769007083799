import React from 'react'
export default function TemplateBody(props) {
  return (
    <div className="hfl:template:body">
       
          {props.children}
     
        </div>
  )
}
