import axios from "axios";
import isEmpty from "../utils/isEmpty";
import setAuthorizationHeaders from "../utils/setAuthorizationHeaders";
import { getUSerEmailFromToken, formattedFilters } from "../utils/helpers";

export const jobsServices = {};

// Get SKUs
jobsServices.getSKUs = async (filters) => {
  let url = `${
    process.env.REACT_APP_QR_API
    }/sku?email=${await getUSerEmailFromToken()}`;

  if (!isEmpty(filters)) {
    url += formattedFilters(filters);
  }
  try {
    let skus = await axios.get(url, {
      headers: await setAuthorizationHeaders(),
    });
    return !isEmpty(skus.data.data) ? skus.data.data : [];
  } catch (error) {
    return [];
  }
};

// Get production order number
jobsServices.getProductionOrderNumber = async () => {
  try {
    let order = await axios.get(
      `${
      process.env.REACT_APP_QR_API
      }/serialization/generateproductionordernumber?email=${await getUSerEmailFromToken()}`,
      {
        headers: await setAuthorizationHeaders(),
      }
    );
    return !isEmpty(order.data.data) &&
      !isEmpty(order.data.data.productionOrderNo)
      ? order.data.data.productionOrderNo
      : "";
  } catch (error) {
    return [];
  }
};

// Get production order number
jobsServices.getBatchId = async () => {
  try {
    let result = await axios.get(
      `${
      process.env.REACT_APP_QR_API
      }/serialization/generatebatchid?email=${await getUSerEmailFromToken()}`,
      {
        headers: await setAuthorizationHeaders(),
      }
    );
    return !isEmpty(result.data.data) && !isEmpty(result.data.data.batchId)
      ? result.data.data.batchId
      : "";
  } catch (error) {
    return [];
  }
};

// Save range based serialization
jobsServices.saveRangeBasedSerialization = async (data) => {
  let userInfo = JSON.parse(localStorage.getItem("userInfo"));
  data.email = userInfo.email;
  try {
    let result = await axios.post(
      `${
      process.env.REACT_APP_QR_API
      }/serialization/insertSerialization?email=${await getUSerEmailFromToken()}`,
      data,
      {
        headers: await setAuthorizationHeaders(),
      }
    );
    return !isEmpty(result.data) ? result.data : {};
  } catch (err) {
    return err.response ? err.response.data : err;
  }
};

// Get jobs
jobsServices.getJobs = async (filterData) => {
  let url = `${
    process.env.REACT_APP_QR_API
    }/serialization/jobs?email=${await getUSerEmailFromToken()}`;

  if (!isEmpty(filterData)) {
    Object.keys(filterData).forEach((key) => {
      if (!isEmpty(filterData[key])) {
        url += `&${key}=${filterData[key]}`;
      }
    });
  }

  try {
    let result = await axios.get(url, {
      headers: await setAuthorizationHeaders(),
    });
    return !isEmpty(result.data.data) ? result.data.data : [];
  } catch (err) {
    return [];
  }
};

// Get range based serializations by job id
jobsServices.getRangeBasedSerialNumbersByJobId = async (jobId) => {
  try {
    let result = await axios.get(
      `${
      process.env.REACT_APP_QR_API
      }/serialization/getgenerationserializationidsbasedonjobid/${jobId}?email=${await getUSerEmailFromToken()}`,
      {
        headers: await setAuthorizationHeaders(),
      }
    );
    return !isEmpty(result.data.data) ? result.data.data : [];
  } catch (err) {
    return [];
  }
};

// Upload SKU/Description Image
jobsServices.uploadSkuImage = async (data) => {
  try {
    let result = await axios.post(
      `${process.env.REACT_APP_TRACK_TRACE_ACCOUNTS_API}/azure/image/upload`,
      data,
      {
        // headers: await setAuthorizationHeaders(),
        headers: { "Content-Type": "multipart/form-data" },
      }
    );
    return !isEmpty(result.data) ? result.data : {};
  } catch (err) {
    return err.response ? err.response.data : err;
  }
};

// Update SKU
jobsServices.updateSku = async (id, data) => {
  try {
    let result = await axios.put(
      `${
      process.env.REACT_APP_QR_API
      }/sku/update/${id}?email=${await getUSerEmailFromToken()}`,
      data,
      {
        headers: await setAuthorizationHeaders(),
      }
    );
    return !isEmpty(result.data) ? result.data : {};
  } catch (err) {
    return err.response ? err.response.data : err;
  }
};

// Save sku
jobsServices.saveSku = async (data) => {
  try {
    let result = await axios.post(
      `${
      process.env.REACT_APP_QR_API
      }/sku?email=${await getUSerEmailFromToken()}`,
      data,
      {
        headers: await setAuthorizationHeaders(),
      }
    );
    return !isEmpty(result.data) ? result.data : {};
  } catch (err) {
    return err.response ? err.response.data : err;
  }
};

// Delete sku
jobsServices.deleteSku = async (skuCode, campaignId) => {
  try {
    let result = await axios.delete(
      `${
      process.env.REACT_APP_QR_API
      }/sku/${skuCode}?email=${await getUSerEmailFromToken()} &campaignId=${campaignId}`,
      {
        headers: await setAuthorizationHeaders(),
      }
    );
    return !isEmpty(result.data) ? result.data : {};
  } catch (err) {
    return err.response ? err.response.data : err;
  }
};

// Get Material Groups
jobsServices.getMaterialGroups = async () => {
  try {
    let skus = await axios.get(
      `${process.env.REACT_APP_SAP_API_URL}/SAP/getMaterialGroups`,
      {
        headers: await jobsServices.getSAPDetails(),
      }
    );
    return !isEmpty(skus.data.data) ? skus.data.data : [];
  } catch (error) {
    return [];
  }
};

// Get Materials
jobsServices.getmaterials = async (group) => {
  try {
    let skus = await axios.get(
      `${process.env.REACT_APP_SAP_API_URL}/SAP/getMaterials/${group}`,
      {
        headers: await jobsServices.getSAPDetails(),
      }
    );
    return !isEmpty(skus.data.data) ? skus.data.data : [];
  } catch (error) {
    return [];
  }
};

jobsServices.getSAPDetails = async () => {
  try {
    let manufacturer = await axios.get(
      `${
      process.env.REACT_APP_QR_API
      }/myInventories/manufacturers?email=${await getUSerEmailFromToken()}`,
      {
        headers: await setAuthorizationHeaders(),
      }
    );
    if (!isEmpty(manufacturer.data.data)) {
      let manufacturerData = manufacturer.data.data[0];

      if (!isEmpty(manufacturerData.sapDetails)) {
        const sapData = manufacturerData.sapDetails;
        var sapDetails = {
          servernode: `${sapData.host}:${sapData.port}`,
          username: sapData.user,
          password: sapData.password,
        };
        return sapDetails;
      } else {
        return {};
      }
    } else {
      return {};
    }
  } catch (error) {
    return {};
  }
};

// Get Secret codes by secret id
jobsServices.downloadSecretCodesById = async (id) => {
  try {
    let result = await axios.get(
      `${
      process.env.REACT_APP_QR_API
      }/secretcodes/exportSecretcodesById/${id}?email=${await getUSerEmailFromToken()}`,
      {
        headers: await setAuthorizationHeaders(),
      }
    );
    return !isEmpty(result.data) ? result.data : {};
  } catch (err) {
    return err.response ? err.response.data : err;
  }
};

// Get Secret Codes
jobsServices.getSecretCodes = async (filters) => {
  let url = `${
    process.env.REACT_APP_QR_API
    }/secretcodes?email=${await getUSerEmailFromToken()}`;

  if (!isEmpty(filters)) {
    url += formattedFilters(filters);
  }
  try {
    let response = await axios.get(url, {
      headers: await setAuthorizationHeaders(),
    });
    return !isEmpty(response.data.data) ? response.data.data : [];
  } catch (error) {
    return [];
  }
};

// Save Secret Codes
jobsServices.createSecretCodes = async (data) => {
  try {
    let result = await axios.post(
      `${
      process.env.REACT_APP_QR_API
      }/secretcodes?email=${await getUSerEmailFromToken()}`,
      data,
      {
        headers: await setAuthorizationHeaders(),
      }
    );
    return !isEmpty(result.data) ? result.data : {};
  } catch (err) {
    return err.response ? err.response.data : err;
  }
};

jobsServices.getSkuDetailsById = async (id) => {
  try {
    let result = await axios.get(
      `${
      process.env.REACT_APP_QR_API
      }/sku/${id}?email=${await getUSerEmailFromToken()}`,
      {
        headers: await setAuthorizationHeaders(),
      }
    );
    return !isEmpty(result.data) ? result.data : {};
  } catch (err) {
    return err.response ? err.response.data : err;
  }
};

// Get Quiz list
jobsServices.getQuiz = async (filters) => {
  // let url = `${process.env.REACT_APP_QR_API}/questions?email=saiprasuna.k@msr-it.com`;
  let url = `${
    process.env.REACT_APP_QR_API
    }/questions?email=${await getUSerEmailFromToken()}`;

  if (!isEmpty(filters)) {
    url += formattedFilters(filters);
  }
  try {
    let response = await axios.get(url, {
      headers: await setAuthorizationHeaders(),
    });
    return !isEmpty(response.data) ? response.data : [];
  } catch (err) {
    return [];
  }
};

//save Questions list
jobsServices.saveQuestion = async (data) => {
  try {
    let result = await axios.post(
      `${
      process.env.REACT_APP_QR_API
      }/questions?email=${await getUSerEmailFromToken()}`,
      data,
      {
        headers: await setAuthorizationHeaders(),
      }
    );
    return !isEmpty(result.data) ? result.data : {};
  } catch (err) {
    return err.response ? err.response.data : err;
  }
};

//get QuestionsById
jobsServices.getQuestionsById = async (id, data) => {
  let url = `${
    process.env.REACT_APP_QR_API
    }/questions/${id}?email=${await getUSerEmailFromToken()}`;

  try {
    let response = await axios.get(url, {
      headers: await setAuthorizationHeaders(),
    });
    return !isEmpty(response.data.data) ? response.data.data : [];
  } catch (err) {
    return [];
  }
};

//update QuestionsById
jobsServices.updateQuestionsById = async (id, data) => {
  try {
    let result = await axios.put(
      `${
      process.env.REACT_APP_QR_API
      }/questions/${id}?email=${await getUSerEmailFromToken()}`,
      data,
      {
        headers: await setAuthorizationHeaders(),
      }
    );
    return !isEmpty(result.data) ? result.data : {};
  } catch (err) {
    return err.response ? err.response.data : err;
  }
};

// Save RegionsList
jobsServices.saveRegion = async (data) => {
  try {
    let result = await axios.post(
      `${
      process.env.REACT_APP_QR_API
      }/regions?email=${await getUSerEmailFromToken()}`,
      data,
      {
        headers: await setAuthorizationHeaders(),
      }
    );
    return !isEmpty(result.data) ? result.data : {};
  } catch (err) {
    return err.response ? err.response.data : err;
  }
};

// Delete RegionsList
jobsServices.deleteRegion = async (regionId) => {
  try {
    let result = await axios.put(
      `${
      process.env.REACT_APP_QR_API
      }/regions/${regionId}?email=${await getUSerEmailFromToken()}`,
      {
        headers: await setAuthorizationHeaders(),
      }
    );
    return !isEmpty(result.data) ? result.data : {};
  } catch (err) {
    return err.response ? err.response.data : err;
  }
};

// Get Blockednumbers list
jobsServices.getBockedNumbers = async (filters) => {
  let url = `${
    process.env.REACT_APP_QR_API
    }/blockedUsers?email=${await getUSerEmailFromToken()}`;

  if (!isEmpty(filters)) {
    url += formattedFilters(filters);
  }
  try {
    let response = await axios.get(url, {
      headers: await setAuthorizationHeaders(),
    });
    return !isEmpty(response.data) ? response.data : [];
  } catch (err) {
    return [];
  }
};

// users Unblock
jobsServices.unblockUser = async (userId) => {
  const url = `${
    process.env.REACT_APP_QR_API
    }/blockedUsers/unBlock?email=${await getUSerEmailFromToken()}`;
  try {
    const response = await axios.put(
      url,
      { userId },
      {
        headers: await setAuthorizationHeaders(),
      }
    );
    return response.data;
  } catch (err) {
    return { isError: true, message: err.message };
  }
};

// Get Reward Notifications list
jobsServices.getNotifications = async () => {
  try {
    let result = await axios.get(`${process.env.REACT_APP_QR_API}/consumer/rewardsNotifications?email=${await getUSerEmailFromToken()}`,
      {
        headers: await setAuthorizationHeaders(),
      }
    );
    return !isEmpty(result.data) ? result.data : [];
  } catch (err) {
    return err.response ? err.response.data : err;
  }
};
