import isEmpty from "./isEmpty";
import * as XLSX from "xlsx";
import { toast } from "react-toastify";
import Moment from "moment";
import Validator from "validator";
import jwt_decode from "jwt-decode";

export const isAllowedPermission = (page) => {
  let userInfo = JSON.parse(localStorage.getItem("userInfo"));
  if (!isEmpty(localStorage.getItem("rolePermissions"))) {
    let rolePermissions = localStorage.getItem("rolePermissions").split(",");

    return userInfo.role === "Super Admin" ||
      rolePermissions.includes(page) ||
      rolePermissions.includes("All")
      ? true
      : false;
  } else {
    return false;
  }
};

export const isAllowedOnboardPermission = (page, category) => {
  let userInfo = JSON.parse(localStorage.getItem("userInfo"));

  if (!isEmpty(localStorage.getItem("rolePermissions"))) {
    let rolePermissions = localStorage.getItem("rolePermissions").split(",");

    return userInfo.role === "Super Admin" ||
      rolePermissions.includes("All") ||
      (userInfo.categoryName === category && rolePermissions.includes(page))
      ? true
      : false;
  } else {
    return false;
  }
};

export const getOnboardUrl = () => {
  let userInfo = JSON.parse(localStorage.getItem("userInfo"));

  if (userInfo.role === "Super Admin") {
    return "manufacturer";
  } else if (!isEmpty(localStorage.getItem("rolePermissions"))) {
    let rolePermissions = localStorage.getItem("rolePermissions").split(",");

    if (rolePermissions.includes("All")) {
      return "manufacturer";
    } else if (userInfo.categoryName === "Logistic") {
      return "logistics";
    } else {
      return userInfo.categoryName.toLowerCase();
    }
  } else {
    if (userInfo.categoryName === "Logistic") {
      return "logistics";
    } else {
      return userInfo.categoryName.toLowerCase();
    }
  }
};

// Export data in excel file
export const exportAsExcel = (data, fileName) => {
  const worksheet = XLSX.utils.json_to_sheet(data);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  XLSX.writeFile(workbook, `${fileName}.xls`);
};

export const redirectToRewardsPage = () => {
  let DifferentWindow = window.open(
    `${
      process.env.REACT_APP_LOYALTY_REWARDS_UI_URL
    }/landing?url=${localStorage.getItem("trackTraceJWToken")}`
  );
  DifferentWindow.localStorage.setItem("Key", "Value");
};

// Get file size from bytes to KB,MB,GB or TB
export const formatBytes = (bytes) => {
  var units = ["B", "KB", "MB", "GB", "TB"],
    i;
  for (i = 0; bytes >= 1024 && i < 4; i++) {
    bytes /= 1024;
  }
  return bytes.toFixed(2) + units[i];
};

// Get Email from token
export const getUSerEmailFromToken = () => {
  let userInfo = JSON.parse(localStorage.getItem("userInfo"));
  return !isEmpty(userInfo) ? userInfo.email : "";
};

// Show toaster message
export const showToastMessage = (message, errorType) => {
  const messageProperties = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  };

  if (errorType === "success") {
    return toast.success(message, messageProperties);
  } else {
    return toast.error(message, messageProperties);
  }
};

// Return formatted date with DD-MM-YYYY format
export const getFormattedDate = (value) => {
  return Moment(value).format("DD-MM-YYYY");
};

// Return formatted date and time with DD-MM-YYYY h:mm:a format
export const getFormattedDateTime = (value) => {
  return Moment(value).format("DD-MM-YYYY, h:mm a");
};

// Return formatted time with h:mm:ss a format
export const getFormattedTimeFromDateTime = (value) => {
  return Moment(value).format("h:mm:ss a");
};

// Return validation message for phone number if not valid
export const validateMobileNumber = (value) => {
  const errors = {};
  if (value && !Validator.isDecimal(value)) {
    errors.phone = "Mobile number is invalid";
  } else if (value && !Validator.isLength(value, { min: 10, max: 10 })) {
    errors.phone = "Phone number should have 10 numbers";
  }
  return errors;
};

// Return true or false if the token is expire for web flow of product scanning
export const checkAuthExpiration = () => {
  const token = sessionStorage.getItem("webusertoken");
  if (!isEmpty(token)) {
    let decoded = jwt_decode(token);
    // Check token expiry
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      sessionStorage.removeItem("webusertoken");
      return true;
    } else {
      return false;
    }
  } else {
    return true;
  }
};

//Return web user token for web flow apis
export const setWebUserAuthorizationHeaders = () => {
  let host = window.location.host;
  const subDomainText = host.split(".")[0];
  return {
    "Content-Type": "application/json",
    Authorization: `Bearer ${sessionStorage.getItem("webusertoken")}`,
    sub_domain:
      subDomainText.includes("localhost") ||
      subDomainText.includes("192") ||
      subDomainText.includes("hflqa") ||
      subDomainText.includes("web-hfl-client-uat") ||
      subDomainText.includes("uathfl")
        ? "hfl"
        : subDomainText,
  };
};

// Return string with Capitalized first letter
export const capitalizeFirstLetter = (str) => {
  return str[0].toUpperCase() + str.slice(1);
};

// Return string with lowercase all letters
export const toLowerCaseAllLetters = (str) => {
  return str[0].toLowerCase() + str.slice(1);
};

// Return month name by passing month number
export const getMonthNameByMonthNum = (month) => {
  const monthsArr = [
    { name: "Jan", value: "1" },
    { name: "Feb", value: "2" },
    { name: "Mar", value: "3" },
    { name: "April", value: "4" },
    { name: "May", value: "5" },
    { name: "June", value: "6" },
    { name: "July", value: "7" },
    { name: "Aug", value: "8" },
    { name: "Sep", value: "9" },
    { name: "Oct", value: "10" },
    { name: "Nov", value: "11" },
    { name: "Dec", value: "12" },
  ];

  const monthObj = monthsArr.find((e) => {
    return e.value === month;
  });
  return !isEmpty(monthObj) ? monthObj.name : month;
};

// Set full date by passing day number
export const setFullDateByDay = (day) => {
  return getFormattedDate(new Date().setDate(day));
};

// redirect to new dashboard page
export const redirectToNewDashboardPage = () => {
  let DifferentWindow = window.open(
    `${
      process.env.REACT_APP_DASHBOARD_UI_URL
    }/landing?url=${localStorage.getItem("trackTraceJWToken")}`
  );
  DifferentWindow.localStorage.setItem("Key", "Value");
};

// Return the filters fields as string
export const formattedFilters = (fields) => {
  let filterString = "";
  if (!isEmpty(fields)) {
    Object.keys(fields).forEach((key) => {
      if (!isEmpty(fields[key])) {
        filterString += `&${key}=${fields[key]}`;
      }
    });
  }
  return filterString;
};

// Return formatted denominations list
export const formatDenominationsList = (data) => {
  let list = [];
  data.map((item) => {
    let obj = { value: item, name: item };
    list.push(obj);
  });

  return list;
};

// Return formatted reward denominations list
export const formatRewardDenominationsList = (data) => {
  let list = [];
  data.map((item) => {
    let obj = {
      denominationValue: item,
      probability: "",
      noOfVouchers: "",
      // isPaymentOnline: false,
    };
    list.push(obj);
  });

  return list;
};

export const validateWhiteSpace = (value) => {
  if (isEmpty(value)) {
    return "Cannot be empty spaces, please enter valid data";
  }
  return true;
};

export const removeInputFieldWhiteSpaces = (e) => {
  e.target.value = e.target.value.trim();
};

// sort array of object by alphabet
export const sortArrayByName = (data, field) => {
  return data.sort((a, b) =>
    (a[field] || "").toString().localeCompare((b[field] || "").toString())
  );
};

// return campaign based email from product details
export const getCampaignSupportMail = (data) => {
  return !isEmpty(data) &&
    !isEmpty(data.campaign) &&
    !isEmpty(data.campaign[0].supportEmail)
    ? data.campaign[0].supportEmail
    : "";
};
// return campaign based support number one from product details
export const getCampaignSupportPhoneNumberOne = (data) => {
  return !isEmpty(data) &&
    !isEmpty(data.campaign) &&
    !isEmpty(data.campaign[0].supportPhoneNumber1)
    ? data.campaign[0].supportPhoneNumber1
    : "";
};
// return campaign based support number two from product details
export const getCampaignSupportPhoneNumberTwo = (data) => {
  return !isEmpty(data) &&
    !isEmpty(data.campaign) &&
    !isEmpty(data.campaign[0].supportPhoneNumber2)
    ? data.campaign[0].supportPhoneNumber2
    : "";
};

// return days difference of two dates
export const getDaysDifference = (from, to) => {
  let date1 = new Date(from);
  let date2 = new Date(to);

  // Calculating the time difference of two dates
  let differenceInTime = date2.getTime() - date1.getTime();

  // Calculating the no. of days between two dates
  let differenceInDays = Math.round(differenceInTime / (1000 * 3600 * 24));
  return differenceInDays;
};
