import React from 'react'
export default function TemplateBadge(props) {
  return (
    <div className='hfl:template:badge'>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
  )
}
