import { combineReducers } from "redux";

import usersreducer from "./usersReducer";
import manufacturerReducer from "./manufacturerReducer";

const rootReducer = combineReducers({
  user: usersreducer,
  manufacturer: manufacturerReducer,
});

export default rootReducer;
