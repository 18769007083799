import React from "react";
export default function TemplateCheckbox(props) {
  return (
    <>
      <div className="hfl:template:checkbox">
        <input
          type="checkbox"
          id={props.id}
          checked={props.checked}
          onChange={props.onChange}
        />
        <label htmlFor={props.id} data-label={props.label}></label>
      </div>
    </>
  );
}
