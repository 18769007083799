import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "react-confirm-alert/src/react-confirm-alert.css";
import "primereact/resources/themes/nova-light/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Provider } from "react-redux";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { store } from "./store";
import axios from "axios";

import { showToastMessage } from "./utils/helpers";

const root = ReactDOM.createRoot(document.getElementById("root"));

// check axios every http request start
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error && error.response && error.response.status === 401) {
      // localStorage.removeItem("trackTraceJWToken");
      // localStorage.removeItem("rolePermissions");
      // localStorage.removeItem("userInfo");
      localStorage.clear();
      alert("Your session got expired!");

      // showToastMessage("Your session got expired!", "error");
      setTimeout(() => {
        // window.location.href = "/";
        // window.location.reload(false);
      }, 3000);
    }
    return Promise.reject(error);
  }
); // check axios every http request end

root.render(
  <BrowserRouter>
    <Provider store={store}>
      {/* <React.StrictMode> */}
      <App />
      {/* </React.StrictMode> */}
    </Provider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
