// Loader
export const SET_LOADER = "SET_LOADER";

export const SET_USER_DETAILS = "SET_USER_DETAILS";
export const SET_ROLES_LIST = "SET_ROLES_LIST";
export const SET_CATEGORIES_LIST = "SET_CATEGORIES_LIST";

/**Manufacture module  */
export const SET_MANUFACTURER_BASIC_DETAILS = "SET_MANUFACTURER_BASIC_DETAILS";
export const SET_MANUFACTURER_BASIC_DETAILS_DELETE =
  "SET_MANUFACTURER_BASIC_DETAILS_DELETE";
export const SET_MANUFACTURER_STORE_DETAILS = "SET_MANUFACTURER_STORE_DETAILS";
export const SET_MANUFACTURER_STORE_DETAILS_DELETE =
  "SET_MANUFACTURER_STORE_DETAILS_DELETE";
export const SET_MANUFACTURER_EXISTING_SYSTEM_DETAILS =
  "SET_MANUFACTURER_EXISTING_SYSTEM_DETAILS";
export const SET_MANUFACTURER_SYSTEM_INTEGRATE_DETAILS =
  "SET_MANUFACTURER_SYSTEM_INTEGRATE_DETAILS";
export const SET_MANUFACTURER_SYSTEM_INTEGRATE_DETAILS_DELETE =
  "SET_MANUFACTURER_SYSTEM_INTEGRATE_DETAILS_DELETE";
export const SET_MANUFACTURER_EXCEL_DETAILS = "SET_MANUFACTURER_EXCEL_DETAILS";
export const SET_MANUFACTURER_PERVIEW_CONFIRM =
  "SET_MANUFACTURER_PERVIEW_CONFIRM";
export const SET_MANUFACTURER_EXISTS = "SET_MANUFACTURER_EXISTS";

export const SET_OTP_SENT = "SET_OTP_SENT";
