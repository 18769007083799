import {
  SET_LOADER,
  SET_USER_DETAILS,
  SET_ROLES_LIST,
  SET_CATEGORIES_LIST,
  SET_OTP_SENT,
} from "../actions/types";

const initialState = {
  isLoading: false,
  userDetails: {},
  roles: [],
  categories: [],
  isOtpSent: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_LOADER:
      return {
        ...state,
        isLoading: action.payload,
      };
    case SET_USER_DETAILS:
      return {
        ...state,
        userDetails: action.payload,
      };
    case SET_ROLES_LIST:
      return {
        ...state,
        roles: action.payload,
      };
    case SET_CATEGORIES_LIST:
      return {
        ...state,
        categories: action.payload,
      };
    case SET_OTP_SENT:
      return {
        ...state,
        isOtpSent: action.payload,
      };
    default:
      return state;
  }
}
