import React from 'react'
import Logo from '../template/images/heritage-logo.svg'
import Template from './Template'
export default function TemplateSpinner(props) {
  return (
    <>

    <div className='hfl:template:spinner'>
    <Template.Columns className="align-items-center">
    <img src={Logo}  />
    <div className="spinner-box">
  <div className="configure-border-1">
    <div className="configure-core"></div>
  </div>
  <div className="configure-border-2">
    <div className="configure-core"></div>
  </div>
</div>
    </Template.Columns>
 
    </div>

 

    </>
  //   <div class="d-flex flex-column align-items-center hfl:template:spinner">
  //   <div class="spinner-border" role="status">
  //     <span class="visually-hidden">Loading...</span>
  //   </div>
  //   {props.label && <div className='mt-2'>{props.label}</div> }
  // </div>
  )
}
