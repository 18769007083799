import React from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  redirectToRewardsPage,
  isAllowedPermission,
} from "../../utils/helpers";
import isEmpty from "../../utils/isEmpty";

export default function Sidebar() {
  const location = useLocation();
  const { pathname } = location;

  const user = useSelector((state) => state.user.userDetails);

  const eraseStorage = () => {
    localStorage.removeItem("selectedRegion");
    localStorage.removeItem("selectedSku");
  };

  return (
    <div className="side-bar" id="nav" onClick={eraseStorage}>
      <nav className="nav__content">
        {/* {isAllowedPermission("Dashboard") ? ( */}
        <Link
          to="/dashboard"
          className={`nav__link ${
            pathname === "/dashboard" ? "active-link" : ""
          }`}
        >
          <span className="material-icons-outlined"> speed </span>
          <span className="nav__name">Dashboard</span>
        </Link>
        {/* ) : (
          ""
        )} */}

        {isAllowedPermission("Serialization") ? (
          <Link
            to="/serialization"
            className={`nav__link ${
              pathname === "/serialization" ? "active-link" : ""
            }`}
          >
            <span className="material-icons-outlined">qr_code_scanner</span>
            <span className="nav__name">Serialization</span>
          </Link>
        ) : (
          ""
        )}

        {isAllowedPermission("Consumer Data") ? (
          <Link
            to="/consumerdata"
            className={`nav__link ${
              pathname === "/consumerdata" ? "active-link" : ""
            }`}
          >
            <span className="material-icons-outlined"> list_alt </span>
            <span className="nav__name">Consumer Data</span>
          </Link>
        ) : (
          ""
        )}

        {isAllowedPermission("Reward Transactions") ? (
          <Link
            to="/consumer-reward-transactions"
            className={`nav__link ${
              pathname === "/consumer-reward-transactions" ? "active-link" : ""
            }`}
          >
            <span className="material-icons-outlined">receipt_long</span>
            <span className="nav__name">Reward Transactions</span>
          </Link>
        ) : (
          ""
        )}
        {isAllowedPermission("Manage Reward") ? (
          <Link
            to="/manage-rewards"
            className={`nav__link ${
              pathname === "/manage-rewards" ? "active-link" : ""
            }`}
          >
            <span className="material-icons-outlined">emoji_events</span>
            <span className="nav__name">Manage Reward</span>
          </Link>
        ) : (
          ""
        )}

        {isAllowedPermission("Quiz") ? (
          <Link
            to="/quiz-questions"
            className={`nav__link ${
              pathname === "/quiz-questions" ? "active-link" : ""
            }`}
          >
            <span class="material-icons-outlined">quiz</span>
            <span className="nav__name">Quiz</span>
          </Link>
        ) : (
          ""
        )}
        {isAllowedPermission("Blocked Users") ? (
          <Link
            to="/blocked-users"
            className={`nav__link ${
              pathname === "/blocked-users" ? "active-link" : ""
            }`}
          >
            <span class="material-icons-outlined">person_off</span>
            <span className="nav__name">Blocked Users</span>
          </Link>
        ) : (
          ""
        )}
        {/* {isAllowedPermission("On Board Stakeholders") ? (
          <Link
            to="/onbaordstakeholders"
            className={`nav__link ${
              pathname === "/onbaordstakeholders" ? "active-link" : ""
            }`}
          >
            <span className="material-icons-outlined"> switch_account </span>
            <span className="nav__name">On Board Stakeholders</span>
          </Link>
        ) : (
          ""
        )} */}

        {isAllowedPermission("User Management") ? (
          <Link
            to="/usermanagement"
            className={`nav__link ${
              pathname === "/usermanagement" ? "active-link" : ""
            }`}
          >
            <span className="material-icons-outlined">manage_accounts</span>
            <span className="nav__name">User Management</span>
          </Link>
        ) : (
          ""
        )}

        {/* {isAllowedPermission("Daily Reports") ? (
          <Link
            to="/daily-reports"
            className={`nav__link ${
              pathname === "/daily-reports" ? "active-link" : ""
            }`}
          >
            <span className="material-icons-outlined">description</span>
            <span className="nav__name">Daily Reports</span>
          </Link>
        ) : (
          ""
        )}

        {isAllowedPermission("Printers Status") ? (
          <Link
            to="/printers-status"
            className={`nav__link ${
              pathname === "/printers-status" ? "active-link" : ""
            }`}
          >
            <span className="material-icons-outlined">local_printshop</span>
            <span className="nav__name">Printers Status</span>
          </Link>
        ) : (
          ""
        )} */}
      </nav>
    </div>
  );
}
