import React, { forwardRef } from "react";
import classNames from 'classnames';
 const TemplateButton = forwardRef(({className,onlyIcon,children,type,label,...rest},ref)=>{
  const defaultClass = 'hfl:template:btn'; 
  return (
    <>
      <button
        type="button"
        ref={ref} 
        {...rest}
        className={classNames(
          defaultClass,
          className,
          type && `${defaultClass}:type:${type}`,
          onlyIcon && `${defaultClass}:onlyicon`

          )}
      >
       {label && <span>{label}</span>}
       {children && <>{children}</>}
      </button>
    </>
  );
})
export default   TemplateButton