import React from 'react'
export default function TemplateFooter(props) {
  return (
    <>
      <div className="hfl:template:footer">
        <div className="hfl:template:footer:inner"></div>
      </div>
    </>
  )
}
