import React from "react";

import isEmpty from "../../../../utils/isEmpty";

export default function TemplateQuiz(props) {
  return (
    <>
      <div className="hfl:template:quiz">
        {!isEmpty(props.options) &&
          props.options.map((opt, i) => (
            <div
            key={i}
              className={
                "hfl:template:quiz:item " +
                (props.errorMessage && props.answer && props.answer === opt
                  ? "hfl:template:quiz:item:wrong"
                  : props.successMsg && props.answer && props.answer === opt
                  ? "hfl:template:quiz:item:correct"
                  : "")
              }
            >
              {/* hfl:template:quiz-item:correct
           hfl:template:quiz-item:wrong */}
              <input
                type="radio"
                name="quiz"
                id={i + 1}
                value={opt}
                onChange={props.onChange}
              />
              <label htmlFor={i + 1}>{opt}</label>
            </div>
          ))}
      </div>
    </>
  );
}
