import {
  SET_MANUFACTURER_BASIC_DETAILS,
  SET_MANUFACTURER_BASIC_DETAILS_DELETE,
  SET_MANUFACTURER_STORE_DETAILS,
  SET_MANUFACTURER_STORE_DETAILS_DELETE,
  SET_MANUFACTURER_EXISTING_SYSTEM_DETAILS,
  SET_MANUFACTURER_SYSTEM_INTEGRATE_DETAILS,
  SET_MANUFACTURER_SYSTEM_INTEGRATE_DETAILS_DELETE,
  SET_MANUFACTURER_EXCEL_DETAILS,
  SET_MANUFACTURER_PERVIEW_CONFIRM,
  SET_MANUFACTURER_EXISTS,
} from "../actions/types";

const initialState = {
  basicDetails: {},
  storeDetails: [],
  systemDetails: [],
  existingSystem: {},
  excelDetails: {},
  manufacturerExist: false,
};

export default function manufacturerReducer(state = initialState, action) {
  switch (action.type) {
    case SET_MANUFACTURER_BASIC_DETAILS:
      return {
        ...state,
        basicDetails: action.payload,
      };

    case SET_MANUFACTURER_BASIC_DETAILS_DELETE:
      let basicDetails = [...state.basicDetails];
      basicDetails.splice(action.payload, 1);
      return {
        ...state,
        basicDetails,
        bootstrapped: basicDetails.length === 0,
      };

    case SET_MANUFACTURER_STORE_DETAILS:
      return { ...state, storeDetails: action.payload };

    case SET_MANUFACTURER_STORE_DETAILS_DELETE:
      let storeDetails = [...state.storeDetails];
      storeDetails.splice(action.payload, 1);
      return {
        ...state,
        storeDetails,
        bootstrapped: storeDetails.length === 0,
      };
    case SET_MANUFACTURER_EXISTING_SYSTEM_DETAILS:
      return {
        ...state,
        existingSystem: action.payload,
        systemDetails: [],
        excelDetails: {},
      };

    case SET_MANUFACTURER_SYSTEM_INTEGRATE_DETAILS:
      return {
        ...state,
        systemDetails: action.payload,
        existingSystem: {},
        excelDetails: {},
      };

    case SET_MANUFACTURER_SYSTEM_INTEGRATE_DETAILS_DELETE:
      let systemDetails = [...state.systemDetails];
      systemDetails.splice(action.payload, 1);
      return {
        ...state,
        systemDetails,
        bootstrapped: systemDetails.length === 0,
      };

    case SET_MANUFACTURER_EXCEL_DETAILS:
      return {
        ...state,
        excelDetails: action.payload,
        existingSystem: {},
        systemDetails: [],
      };

    case SET_MANUFACTURER_PERVIEW_CONFIRM:
      return {
        ...state,
        basicDetails: [],
        storeDetails: [],
        systemDetails: [],
        existingSystem: {},
        excelDetails: {},
        manufacturerExist: false,
      };

    case SET_MANUFACTURER_EXISTS:
      return {
        ...state,
        manufacturerExist: action.payload,
      };

    default:
      return state;
  }
}
