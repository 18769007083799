import React, { Suspense } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Index from "./components/verify-product/HFL-DOODHPEDA/Index";

// Adding lazy loading components
const NotFound = React.lazy(() => import("./components/layouts/NotFound"));
const Maintenance = React.lazy(() =>
  import("./components/layouts/MaintenancePage")
);
const ProtectedRoute = React.lazy(() =>
  import("./components/common/ProtectedRoute")
);
const Login = React.lazy(() => import("./components/authentication/Login"));
const ForgotPassword = React.lazy(() =>
  import("./components/authentication/forgotpassword/ForgotPassword")
);
const Dashboard = React.lazy(() => import("./components/dashboard/Dashboard"));
const StakeholdersTabs = React.lazy(() =>
  import("./components/onboardinventory/StakeholdersTabs")
);
const SerializationTabs = React.lazy(() =>
  import("./components/serialization/serializationTabs")
);
const UserManagement = React.lazy(() =>
  import("./components/user-management/UserManagement")
);
const ConsumerDataTabs = React.lazy(() =>
  import("./components/consumerData/ConsumerDataTabs")
);
const Stakeholders = React.lazy(() =>
  import("./components/stakeholders/Stakeholders")
);
const ViewMyInventoriesModal = React.lazy(() =>
  import("./components/stakeholders/ViewMyInventoriesModal")
);
const SignUp = React.lazy(() => import("./components/signUp/SignUp"));
const ManufacturerLoginResetPassword = React.lazy(() =>
  import("./components/signUp/ManufacturerLoginResetPassword")
);
const VerifiedProductDetails = React.lazy(() =>
  import("./components/verify-product/VerifiedProductDetails")
);
const ProductDetails = React.lazy(() =>
  import("./components/verify-product/ProductDetails")
);

const MobileNumber = React.lazy(() =>
  import("./components/verify-product/VerifyMobileNumber")
);
const OtpCode = React.lazy(() =>
  import("./components/verify-product/VerifyOtpCode")
);
const PersonalDetails = React.lazy(() =>
  import("./components/verify-product/PersonalDetails")
);
const VerifyUniqueNumber = React.lazy(() =>
  import("./components/verify-product/VerifyUniqueNumber")
);
const Quiz = React.lazy(() => import("./components/verify-product/Quiz"));
const Payment = React.lazy(() => import("./components/verify-product/Payment"));
const ProductDetailsVerifiedReward = React.lazy(() =>
  import("./components/verify-product/ProductDetailsVerifiedReward")
);
const VerifyMessage = React.lazy(() =>
  import("./components/verify-product/VerifyMessage")
);
const CongratsMessage = React.lazy(() =>
  import("./components/verify-product/CongratsMessage")
);
const RewardConfirm = React.lazy(() =>
  import("./components/verify-product/RewardConfirm")
);
const TermsAndConditions = React.lazy(() =>
  import("./components/verify-product/TermsAndConditions")
);
const HowToParticipate = React.lazy(() =>
  import("./components/verify-product/HowToPartcipate")
);
const ShareReward = React.lazy(() =>
  import("./components/verify-product/ShareReward")
);

const MyProfile = React.lazy(() => import("./components/profile/MyProfile"));
const ChangePassword = React.lazy(() =>
  import("./components/profile/ChangePassword")
);
const Reports = React.lazy(() => import("./components/reports/Reports"));
const PrinterStatus = React.lazy(() =>
  import("./components/printer/PrinterStatus")
);
const ConsumerRewardTransactions = React.lazy(() =>
  import("./components/dashboard/reward-transactions/ConsumerTransactions")
);

const ManageRewards = React.lazy(() =>
  import("./components/rewards/RewardsList")
);
const QuizWin = React.lazy(() => import("./components/Quiz/QuizWin"));

const BlockedUsers = React.lazy(() =>
  import("./components/BlockedUsers/BlockedUsers")
);

const VerifyProductLocation = React.lazy(() =>
  import("./components/verify-product/VerifyProductLocation")
);
const VerifyPnoneNo = React.lazy(() =>
  import("./components/verify-product/VerifyPnoneNo")
);

/* HFL-Ghee New UI Routes start */
const SelectRegion = React.lazy(() =>
  import("./components/verify-product/HFL-GHEE/Region")
);
const VerifyMobile = React.lazy(() =>
  import("./components/verify-product/HFL-GHEE/VerifyMobile")
);
const VerifyOtp = React.lazy(() =>
  import("./components/verify-product/HFL-GHEE/VerifyOtp")
);
const VerifySecretCode = React.lazy(() =>
  import("./components/verify-product/HFL-GHEE/VerifySecretCode")
);
const AnswerQuiz = React.lazy(() =>
  import("./components/verify-product/HFL-GHEE/Quiz")
);
const Congrats = React.lazy(() =>
  import("./components/verify-product/HFL-GHEE/Congrats")
);
const VerifyUPI = React.lazy(() =>
  import("./components/verify-product/HFL-GHEE/VerifyUPI")
);
const ContactSupport = React.lazy(() =>
  import("./components/verify-product/HFL-GHEE/ContactSupport")
);
const HG_TermsAndConditions = React.lazy(() =>
  import("./components/verify-product/HFL-GHEE/HG_TermsAndConditions")
);
const HG_BankDetails = React.lazy(() =>
  import("./components/verify-product/HFL-GHEE/HG_BankDetails")
);
/* HFL-Ghee New UI Routes end */

/* HFL-DOODHPEDA New UI Routes start */
const HD_SelectRegion = React.lazy(() =>
  import("./components/verify-product/HFL-DOODHPEDA/Region")
);
const HD_VerifyMobile = React.lazy(() =>
  import("./components/verify-product/HFL-DOODHPEDA/VerifyMobile")
);
const HD_VerifyOtp = React.lazy(() =>
  import("./components/verify-product/HFL-DOODHPEDA/VerifyOtp")
);
const HD_VerifySecretCode = React.lazy(() =>
  import("./components/verify-product/HFL-DOODHPEDA/VerifySecretCode")
);
const HD_Quiz = React.lazy(() =>
  import("./components/verify-product/HFL-DOODHPEDA/Quiz")
);
const HD_Congrats = React.lazy(() =>
  import("./components/verify-product/HFL-DOODHPEDA/Congrats")
);
const HD_VerifyUPI = React.lazy(() =>
  import("./components/verify-product/HFL-DOODHPEDA/VerifyUPI")
);
const HD_ContactSupport = React.lazy(() =>
  import("./components/verify-product/HFL-DOODHPEDA/ContactSupport")
);
const HD_BankDetails = React.lazy(() =>
  import("./components/verify-product/HFL-DOODHPEDA/BankDetails")
);
const HD_TermsAndConditions = React.lazy(() =>
  import("./components/verify-product/HFL-DOODHPEDA/TermsAndConditions")
);
/* HFL-DOODHPEDA New UI Routes end */

export default function Routings() {
  return (
    <div>
      <Suspense>
        <Routes>
          <Route path="/404" element={<NotFound />} />
          <Route path="*" element={<Navigate to="/404" replace />} />
          <Route path="/maintenance" element={<Maintenance />} />
          <Route path="/" element={<Login />} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />
          <Route path="/signup" element={<SignUp />} />
          <Route
            path="/manufacturer-login-reset-password"
            element={<ManufacturerLoginResetPassword />}
          />
          <Route element={<ProtectedRoute />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/onbaordstakeholders" element={<StakeholdersTabs />} />
            <Route path="/serialization" element={<SerializationTabs />} />
            <Route path="/usermanagement" element={<UserManagement />} />
            <Route path="/consumerdata" element={<ConsumerDataTabs />} />
            <Route path="/stakeholders" element={<Stakeholders />} />
            <Route
              path="/viewstakeholders/:docType"
              element={<ViewMyInventoriesModal />}
            />
            <Route path="/myprofile" element={<MyProfile />} />
            <Route path="/changepassword" element={<ChangePassword />} />
            <Route path="/daily-reports" element={<Reports />} />
            <Route path="/printers-status" element={<PrinterStatus />} />
            <Route
              path="/consumer-reward-transactions"
              element={<ConsumerRewardTransactions />}
            />
            <Route path="/manage-rewards" element={<ManageRewards />} />
            <Route path="/quiz-questions" element={<QuizWin />} />
            <Route path="/blocked-users" element={<BlockedUsers />} />
          </Route>
          {/* <Route path="/v" element={<VerifiedProductDetails />} /> */}
          <Route path="/v" element={<VerifyProductLocation />} />
          <Route path="/verify-phoneno" element={<VerifyPnoneNo />} />
          <Route
            path="/verifiedproductdetails"
            element={<VerifiedProductDetails />}
          />{" "}
          <Route path="/product-details" element={<ProductDetails />} />
          <Route path="/verifymobilenumber" element={<MobileNumber />} />
          <Route path="/verifyotpcode" element={<OtpCode />} />
          <Route path="/personal-details" element={<PersonalDetails />} />
          <Route
            path="/verify-unique-number"
            element={<VerifyUniqueNumber />}
          />
          <Route path="/answer-and-win" element={<Quiz />} />
          <Route path="/payment" element={<Payment />} />
          <Route
            path="/product-details-verified-reward"
            element={<ProductDetailsVerifiedReward />}
          />
          <Route path="/verify-message" element={<VerifyMessage />} />
          <Route path="/congrats-message" element={<CongratsMessage />} />
          <Route path="/reward-confirm" element={<RewardConfirm />} />
          <Route
            path="/terms-and-conditions"
            element={<TermsAndConditions />}
          />
          <Route path="/howto-participate" element={<HowToParticipate />} />
          <Route path="/share-reward" element={<ShareReward />} />
          {/* <Route path="/product-locations" element={<ProductLocations />} /> */}
          {/* HFL-Ghee Campaign New UI Routes start */}
          <Route path="/hfl-ghee/select-region" element={<SelectRegion />} />
          <Route path="/hfl-ghee/verify-mobile" element={<VerifyMobile />} />
          <Route path="/hfl-ghee/verify-otp" element={<VerifyOtp />} />
          <Route
            path="/hfl-ghee/verify-secret-code"
            element={<VerifySecretCode />}
          />
          <Route path="/hfl-ghee/answer-quiz" element={<AnswerQuiz />} />
          <Route path="/hfl-ghee/congrats" element={<Congrats />} />
          <Route path="/hfl-ghee/verify-upi" element={<VerifyUPI />} />
          <Route
            path="/hfl-ghee/contact-support"
            element={<ContactSupport />}
          />
          <Route
            path="/hfl-ghee/terms-and-conditions"
            element={<HG_TermsAndConditions />}
          />
          <Route path="/hfl-ghee/bank-details" element={<HG_BankDetails />} />
          {/* HFL-Ghee Campaign New UI Routes end */}
          {/* HFL-Doodhpeda Campaign New UI Routes start */}
          <Route path="/hfl-doodhpeda" element={<Index />} />
          <Route
            path="/hfl-doodhpeda/select-region"
            element={<HD_SelectRegion />}
          />
          <Route
            path="/hfl-doodhpeda/verify-mobile"
            element={<HD_VerifyMobile />}
          />
          <Route path="/hfl-doodhpeda/verify-otp" element={<HD_VerifyOtp />} />
          <Route
            path="/hfl-doodhpeda/verify-secret-code"
            element={<HD_VerifySecretCode />}
          />
          <Route path="/hfl-doodhpeda/answer-quiz" element={<HD_Quiz />} />
          <Route path="/hfl-doodhpeda/congrats" element={<HD_Congrats />} />
          <Route path="/hfl-doodhpeda/verify-upi" element={<HD_VerifyUPI />} />
          <Route
            path="/hfl-doodhpeda/contact-support"
            element={<HD_ContactSupport />}
          />
          <Route
            path="/hfl-doodhpeda/bank-details"
            element={<HD_BankDetails />}
          />
          <Route
            path="/hfl-doodhpeda/terms-and-conditions"
            element={<HD_TermsAndConditions />}
          />
          {/* HFL-Doodhpeda Campaign New UI Routes end */}
        </Routes>
      </Suspense>
    </div>
  );
}
