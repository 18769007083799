import React from 'react';
import Gift from "./images/gift-box.png";
import TemplateCard from './TemplateCard';
export default function TemplateGift(props) {
  return (
    <TemplateCard>
    <p className="hfl:template:card:text"><span>₹</span> <span>{props.value || 0}</span> </p>
    <img src={Gift} alt="logo" className='img-fluid' />
    </TemplateCard>
  
  )
}
