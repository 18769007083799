import React, { forwardRef } from "react";
      
  const TemplateInput = forwardRef(({children,wrapper,...rest}, ref) => {
  return (
    <div className={`hfl:template:input ${wrapper ? wrapper?.className : ''}`}>
      <input
        ref={ref}
        {...rest}
      />
      {children}
    </div>
  );
});
export default TemplateInput;
