import classNames from 'classnames';
 

import React, { forwardRef } from 'react'; 
const TemplateColumns = forwardRef(({className,gutter,children,...rest},ref)=>{
  return (
    <div 
    className={classNames('hfl:template:columns',className)}
     style={{'--hfl-template-columns-gutter':gutter ? gutter :  '5px'}}
    ref={ref} {...rest}
    >

      {children}
     
   </div>
  )
},)

export default TemplateColumns;