// components/NotificationBar/NotificationBar.js
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { jobsServices } from "../../services/jobsServices";
import isEmpty from "../../utils/isEmpty";

const NotificationBar = () => {
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    // Fetch data when the component mounts
    fetchNotifications();

    // Set up interval to fetch data every 5 minutes
    const intervalId = setInterval(fetchNotifications, 5 * 60 * 1000); // 5 minutes in milliseconds

    // Clean up interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  // Function to fetch data from the API
  const fetchNotifications = async () => {
    try {
      let userInfo = JSON.parse(localStorage.getItem("userInfo"));
      if (!isEmpty(userInfo)) {
        const response = await jobsServices.getNotifications();
        if (!isEmpty(response) && !isEmpty(response.data)) {
          setNotifications(response.data);
        } else {
          setNotifications([]);
        }
      }
    } catch (err) {
      setNotifications([]);
    }
  };

  // Add width to mySidenav id
  setTimeout(() => {
    if (!isEmpty(notifications) && document.getElementById("mySidenav")) {
      document.getElementById("mySidenav").style.width = "45%";
    }
  }, 0);

  //remove width to mySidenav id and close
  const onClose = () => {
    setNotifications([])
    document.getElementById("mySidenav").style.width = "0";
  };

  return (
    <div>
      {!isEmpty(notifications) &&
        <div id="mySidenav" className="right-side-slider">
          <div className="rs-hw-header">
            <div className="modal-header">
              <h6>
                <span> Reward Notifications </span>&nbsp;
            </h6>
              <button type="button" className="btn close" onClick={onClose}>
                <span aria-hidden="true">×</span>
              </button>
            </div>
          </div>
          <div className="right-slider-hw-content">
            <div className="hw-container">
              <div className="tab-content" id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  id="contact"
                  role="tabpanel"
                  aria-labelledby="contact-tab"
                >
                  <div className="col-md-12">
                    <div className="table-content">
                      <table className="table">
                        <thead>
                          <tr>
                            <th scope="col">#</th>
                            <th scope="col">Mobile Number</th>
                            <th scope="col">Winning Amount</th>
                            <th scope="col">SKU</th>
                            <th scope="col">Region</th>
                            <th scope="col">Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {!isEmpty(notifications) && notifications.map((item, i) => (
                            <tr key={i}>
                              <td>{i + 1}</td>
                              <td>{item.phoneNumber}</td>
                              <td>{item.winningValue}</td>
                              <td>{!isEmpty(item.sku_details) ? item.sku_details.skuName : "-"}</td>
                              <td>{!isEmpty(item.region_details) ? item.region_details.regionName : "-"}</td>
                              <td>
                                <div className="underline">
                                  <Link
                                    to="/consumer-reward-transactions">
                                    <span
                                      style={{ cursor: "pointer" }}
                                      className="material-symbols-outlined"
                                      onClick={() => setNotifications([])}
                                    >
                                      visibility
                                    </span>
                                  </Link>
                                </div></td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="bottom-btn d-flex justify-content-end">
                      <a className="btn in-active-btn me-2" onClick={onClose}>
                        Close
                    </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  );
};

export default NotificationBar;
