const setAuthorizationHeaders = () => {
  let host = window.location.host;
  const subDomainText = host.split(".")[0];
  return {
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.getItem("trackTraceJWToken")}`,
    sub_domain:
      subDomainText.includes("localhost") ||
      subDomainText.includes("192") ||
      subDomainText.includes("hflqa") ||
      subDomainText.includes("web-hfl-client-uat") ||
      subDomainText.includes("uathfl")
        ? "hfl"
        : subDomainText,
  };
};

export default setAuthorizationHeaders;
