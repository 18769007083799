import React, { forwardRef } from "react";

import isEmpty from "../../../../utils/isEmpty";

 const TemplateSelect =   forwardRef(({label,property,children,options,...rest},ref)=>{
  {
    return (
      <div className="hfl:template:select">
        <select ref={ref} {...rest}>
          <option>Select {label}</option>
          {!isEmpty(options) &&
            options.map((item, i) => (
              <option value={item[property.value]}>{item[property.name]}</option>
            ))}
        </select>

        {children && <div className="hfl:template:select:extra">{children}</div>}
      </div>
    );
  }
 }) 

export default TemplateSelect;