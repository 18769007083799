import React from 'react'
export default function TemplateCard(props) {
  return (
    <div className="hfl:template:card">
    <div className="hfl:template:card:body">
     {props.children}
    </div>
    </div>
  )
}
