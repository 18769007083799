import React from "react";
import { useNavigate } from "react-router-dom";

import "./scss/template.scss";
import TemplateHeader from "./TemplateHeader";
import TemplateFooter from "./TemplateFooter";
import TemplateBody from "./TemplateBody";
import TemplateBadge from "./TemplateBadge";
import TemplateInput from "./TemplateInput";
import TemplateCheckbox from "./TemplateCheckbox";
import TemplateButton from "./TemplateButton";
import TemplateText from "./TemplateText";
import TemplateSelect from "./TemplateSelect";
import TemplateQuiz from "./TemplateQuiz";
import TemplateGift from "./TemplateGift";
import TemplateColumns from "./TemplateColumns";
import TemplateRows from "./TemplateRows";
import TemplateError from "./TemplateError";
import TemplateSpinner from "./TemplateSpinner";
import TemplateContainer from "./TemplateContainer";
import TemplateEditor from "./TemplateEditor";
import TemplateCard from "./TemplateCard";
import classNames from "classnames";

function Template(props) {
  const navigate = useNavigate();

  return (
    <div className="hfl:template:wrapper">
      <div
        className={classNames("hfl:template", props.bg && "hfl:template:bg")}
      >
        {props.children}
      </div>
    </div>
  );
}

Template.Container = TemplateContainer;
Template.Header = TemplateHeader;
Template.Body = TemplateBody;
Template.Footer = TemplateFooter;
Template.Badge = TemplateBadge;
Template.Input = TemplateInput;
Template.Select = TemplateSelect;
Template.Checkbox = TemplateCheckbox;
Template.Buttton = TemplateButton;
Template.Text = TemplateText;
Template.Quiz = TemplateQuiz;
Template.Gift = TemplateGift;
Template.Error = TemplateError;
Template.Columns = TemplateColumns;
Template.Rows = TemplateRows;
Template.Spinner = TemplateSpinner;
Template.Editor = TemplateEditor;
Template.Card = TemplateCard;

export default Template;
