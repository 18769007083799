import axios from "axios";

import isEmpty from "../utils/isEmpty";

import setAuthorizationHeaders from "../utils/setAuthorizationHeaders";
import {
  getUSerEmailFromToken,
  setWebUserAuthorizationHeaders,
} from "../utils/helpers";

export const commonServices = {};

// Get Regions list
commonServices.getRegions = async () => {
  try {
    let response = await axios.get(
      `${
        process.env.REACT_APP_QR_API
      }/regions?email=${await getUSerEmailFromToken()}`,
      {
        headers: await setAuthorizationHeaders(),
      }
    );
    return !isEmpty(response.data.data) ? response.data.data : [];
  } catch (err) {
    return [];
  }
};

// Get Active Campaigns list
commonServices.getActiveCampaigns = async (activeCampaigns) => {
  let url = `${
    process.env.REACT_APP_QR_API
  }/campaign/getActiveCampaigns?email=${await getUSerEmailFromToken()}`;
  if (activeCampaigns === true) {
    url += `&activeCampaigns=${activeCampaigns}`;
  }
  try {
    let response = await axios.get(url, {
      headers: await setAuthorizationHeaders(),
    });
    return !isEmpty(response.data.data) ? response.data.data : [];
  } catch (err) {
    return [];
  }
};

// Get skus list based on campaign
commonServices.getCampaignBasedSkus = async (campaignId) => {
  try {
    let response = await axios.get(
      `${
        process.env.REACT_APP_QR_API
      }/sku/skuCodes/${campaignId}?email=${await getUSerEmailFromToken()}`,
      {
        headers: await setAuthorizationHeaders(),
      }
    );
    return !isEmpty(response.data.data) ? response.data.data : [];
  } catch (err) {
    return [];
  }
};
// Get regions list based on sku
commonServices.getSkuBasedRegions = async (skuId, from) => {
  try {
    let response = await axios.get(
      `${
        process.env.REACT_APP_QR_API
      }/sku/regions/${skuId}?email=${await getUSerEmailFromToken()}`,
      {
        headers:
          !isEmpty(from) && from === "Consumer"
            ? await setWebUserAuthorizationHeaders()
            : await setAuthorizationHeaders(),
      }
    );
    return !isEmpty(response.data.data) ? response.data.data : [];
  } catch (err) {
    return [];
  }
};

// Get active regions list based on sku
commonServices.getActiveRegions = async (skuId) => {
  try {
    let response = await axios.get(
      `${
        process.env.REACT_APP_QR_API
      }/sku/activeRegions/${skuId}?email=${await getUSerEmailFromToken()}`,
      {
        headers: await setAuthorizationHeaders(),
      }
    );
    return !isEmpty(response.data.data) ? response.data.data : [];
  } catch (err) {
    return [];
  }
};

// Get denominations list
commonServices.getAvailableDenominations = async () => {
  try {
    let response = await axios.get(
      `${
        process.env.REACT_APP_QR_API
      }/denominations/data?email=${await getUSerEmailFromToken()}`,
      {
        headers: await setAuthorizationHeaders(),
      }
    );
    return !isEmpty(response.data.data) ? response.data.data : [];
  } catch (err) {
    return [];
  }
};

//get region based skus

commonServices.getRegionBasedSkus = async (regionId, campaignId) => {
  try {
    let response = await axios.get(
      `${
        process.env.REACT_APP_QR_API
      }/sku/byRegion/${regionId}?email=${await getUSerEmailFromToken()}&campaignId=${campaignId}`,
      {
        headers: await setAuthorizationHeaders(),
      }
    );
    return !isEmpty(response.data.data) ? response.data.data : [];
  } catch (err) {
    return [];
  }
};

// Get skus list based on campaign
commonServices.getCampaignBasedRegions = async (campaignId) => {
  try {
    let response = await axios.get(
      `${
        process.env.REACT_APP_QR_API
      }/regions/campaign/${campaignId}?email=${await getUSerEmailFromToken()}`,
      {
        headers: await setAuthorizationHeaders(),
      }
    );
    return !isEmpty(response.data.data) ? response.data.data : [];
  } catch (err) {
    return [];
  }
};

// To get deployment status
commonServices.getDeploymentStatus = async () => {
  try {
    let response = await axios.get(
      `${process.env.REACT_APP_TRACK_TRACE_ACCOUNTS_API}/permissions/settings/maintenance`,
      {
        headers: await setAuthorizationHeaders(),
      }
    );
    return !isEmpty(response.data) ? response.data : {};
  } catch (err) {
    return err.response ? err.response.data : err;
  }
};
