import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";

import isEmpty from "../../utils/isEmpty";
import { redirectToNewDashboardPage } from "../../utils/helpers";

export default function Navbar() {
  const navigate = useNavigate();
  let userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const [isOptionsVisible, setOptionsVisible] = useState(false);

  const userLabel = (name) => {
    const words = name.split(" ");
    for (let i = 0; i < words.length; i++) {
      if (!isEmpty(words[i])) {
        words[i] = words[i][0].toUpperCase() + words[i].substr(1);
      }
    }
    return words.join(" ");
  };

  const userName = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(0, 0);
  };

  const handleOptionsVisible = () => {
    setOptionsVisible(true);
  };

  const signout = () => {
    localStorage.removeItem("trackTraceJWToken");
    localStorage.removeItem("userInfo");
    localStorage.removeItem("rolePermissions");
    localStorage.removeItem("selectedCampaign");
    navigate("/");
  };

  const hideOptions = (e) => {
    if (e && e.relatedTarget) {
      e.relatedTarget.click();
    }
    setOptionsVisible(false);
  };

  return (
    <nav className="top-nav">
      <div className="container-fluid">
        <div className="row justify-content-between">
          <div className="col-auto g-0">
            <Link to="/dashboard">
              {!isEmpty(userInfo) && !isEmpty(userInfo.companyLogo) ? (
                <img src={userInfo.companyLogo} alt="Logo" />
              ) : (
                ""
              )}

              {/* <span className="org-name">
                <p className="m-0">
                  {!isEmpty(userInfo.organization) ? userInfo.organization : ""}
                </p>
              </span> */}
            </Link>
          </div>
          <div className="col-auto align-self-center d-flex r-nav-top">
            {/* <div className="nav-db">
              <a onClick={redirectToNewDashboardPage} className="btn">
                View New Dashboard
              </a>
            </div> */}
            <div className="login_user_det">
              <span className="salution">Hello,&nbsp;</span>
              <span className="user-name">
                {!isEmpty(userInfo) && !isEmpty(userInfo.firstName)
                  ? userLabel(userInfo.firstName)
                  : ""}{" "}
                {!isEmpty(userInfo) && !isEmpty(userInfo.lastName)
                  ? userLabel(userInfo.lastName)
                  : ""}
              </span>
            </div>
            <div className="btn-group">
              <button
                type="button"
                className="btn dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                onClick={handleOptionsVisible}
                onBlur={hideOptions}
              >
                <span className="l-user-first-letter">
                  {!isEmpty(userInfo) && !isEmpty(userInfo.firstName)
                    ? userName(userInfo.firstName)
                    : ""}
                </span>
              </button>
              <ul
                className={`dropdown-menu dropdown-menu-end profile-dropdown ${
                  isOptionsVisible && "show"
                }`}
              >
                <li>
                  <Link
                    to="/myprofile"
                    onClick={() => setOptionsVisible(false)}
                    className="dropdown-item"
                  >
                    Profile
                  </Link>
                </li>
                {/* <li>
                  <a className="dropdown-item" href="#">
                    My Account
                  </a>
                </li> */}
                <div className="dropdown-divider"></div>
                <li>
                  <a className="dropdown-item" onClick={signout} href="#">
                    Log Out
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}
