import axios from "axios";

import {
  SET_LOADER,
  SET_USER_DETAILS,
  SET_ROLES_LIST,
  SET_CATEGORIES_LIST,
  SET_OTP_SENT,
} from "./types";
import { getUSerEmailFromToken } from "../utils/helpers";
import isEmpty from "../utils/isEmpty";
import setAuthorizationHeaders from "../utils/setAuthorizationHeaders";

const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${localStorage.getItem("trackTraceJWToken")}`,
};

export const setLoader = (status) => (dispatch) => {
  dispatch({ type: SET_LOADER, payload: status });
};

export const setUserDetails = (data) => async (dispatch) => {
  try {
    let res = await axios.get(
      `${
        process.env.REACT_APP_TRACK_TRACE_ACCOUNTS_API
      }/users/profile?email=${await getUSerEmailFromToken()}`,
      {
        headers: headers,
      }
    );
    if (!isEmpty(res.data.data)) {
      dispatch({ type: SET_USER_DETAILS, payload: res.data.data });
    } else {
      dispatch({ type: SET_USER_DETAILS, payload: {} });
    }
  } catch (error) {
    dispatch({ type: SET_USER_DETAILS, payload: {} });
  }
};

export const updateUserState = (data) => async (dispatch) => {
  dispatch({ type: SET_USER_DETAILS, payload: data });
};

// Set roles list
export const setRoles = (data) => async (dispatch) => {
  let host = window.location.host;
  const subDomainText = host.split(".")[0];
  try {
    let res = await axios.get(
      `${
        process.env.REACT_APP_TRACK_TRACE_ACCOUNTS_API
      }/roles?email=${await getUSerEmailFromToken()}`,
      {
        headers: setAuthorizationHeaders(),
      }
    );
    dispatch({
      type: SET_ROLES_LIST,
      payload: !isEmpty(res.data.data) ? res.data.data : [],
    });
  } catch (err) {
    dispatch({
      type: SET_ROLES_LIST,
      payload: [],
    });
  }
};

// Set categories/stakeholders list
export const setStakeholders = (data) => async (dispatch) => {
  try {
    let res = await axios.get(
      `${
        process.env.REACT_APP_TRACK_TRACE_ACCOUNTS_API
      }/categories?email=${await getUSerEmailFromToken()}`,
      {
        headers: headers,
      }
    );
    dispatch({
      type: SET_CATEGORIES_LIST,
      payload: !isEmpty(res.data.data) ? res.data.data : [],
    });
  } catch (err) {
    dispatch({
      type: SET_CATEGORIES_LIST,
      payload: [],
    });
  }
};

export const setOtpSent = (status) => (dispatch) => {
  dispatch({ type: SET_OTP_SENT, payload: status });
};
