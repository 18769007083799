import React from "react";

import { useLottie } from "lottie-react";
import LoaderProcessing from "../animations/loader.json";

export default function Loader(props) {
  //animations start
  const options = {
    animationData: LoaderProcessing,
    loop: true,
  };
  const { View } = useLottie(options);
  //animations end
  return (
    <>
      <div className="overlay active"></div>
      <div className="full-screen-loader lr-fields">{View}</div>
    </>
  );
}

// import React from "react";

// export default function Loader() {
//   return (
//     <>
//       <div className="overlay active"></div>
//       <div className="full-screen-loader lr-fields">
//         <div className="spinner-grow text-primary" role="status">
//           <span className="sr-only">Loading...</span>
//         </div>
//         <div className="spinner-grow text-secondary" role="status">
//           <span className="sr-only">Loading...</span>
//         </div>
//         <div className="spinner-grow text-success" role="status">
//           <span className="sr-only">Loading...</span>
//         </div>
//         <div className="spinner-grow text-danger" role="status">
//           <span className="sr-only">Loading...</span>
//         </div>
//         <div className="spinner-grow text-warning" role="status">
//           <span className="sr-only">Loading...</span>
//         </div>
//         <div className="spinner-grow text-info" role="status">
//           <span className="sr-only">Loading...</span>
//         </div>
//         <div className="spinner-grow text-light" role="status">
//           <span className="sr-only">Loading...</span>
//         </div>
//         <div className="spinner-grow text-dark" role="status">
//           <span className="sr-only">Loading...</span>
//         </div>
//       </div>
//     </>
//   );
// }
