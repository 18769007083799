import React, { forwardRef } from 'react'; 
const TemplateRows = forwardRef(({className,children,...rest},ref)=>{
  return (
    <div className={`hfl:template:rows ${className}`} ref={ref} {...rest}>

      {children}
     
   </div>
  )
},)

export default TemplateRows;