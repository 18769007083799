import React, { forwardRef } from "react";
import classNames from 'classnames';
const TemplateText =  forwardRef(({className,type,theme,weight,align,children,...rest},ref)=>{
  const defaultClass = 'hfl:template:text';
  return (
    <>
      <span
      ref={ref}
      {...rest}
        className={classNames(
          defaultClass,
          className,
          `${defaultClass}:type:${type}`,
          `${defaultClass}:theme:${theme}`,
          `${defaultClass}:weight:${weight}`,
          `${defaultClass}:align:${align}`
        )}
        
      >
        <span>{children}</span>
      </span>
    </>
  );
})


export default TemplateText